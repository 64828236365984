<template>
	<div class="about_US">
		<div class="page_banner">			<img src="@/assets/images/about/about.jpg" />
			<div class="words">
				<p>
					<span>企业文化</span>
					以人为本，创新科技
				</p>
			</div>
		</div>
		<menu-layout></menu-layout>
		<div class="layout profile clearfix">
			<h1 class="enter">
				企业文化
			</h1>

			<div class="cont">
				<section class="brand_B">
					<!-- <h3 class="title">品牌文化<i></i></h3> -->
					<ul>
						<li>
							<span><img src="@/assets/images/about/brand_03.png"></span>
							<h3>品牌定位</h3>
							<p>打造民族品牌为使命</p>
						</li>
						<li>
							<span><img src="@/assets/images/about/brand_04.png"></span>
							<h3>品牌主张</h3>
							<p>以人为本，创新科技</p>
						</li>
						<li>
							<span><img src="@/assets/images/about/brand_05.jpg"></span>
							<h3>品牌价值观</h3>
							<p>诚信，共创，共赢，共享，敬业，创新</p>
						</li>
					</ul>
				</section>
			</div>
		</div>
	</div>
</template>
<script>
import menuLayout from "@/components/aboutMenu"
export default ({
	components: {
		menuLayout
	},
	methods: {

	}
});
</script>
<style lang="less" scoped>
.brand_B {
	padding-bottom: 120px;
	overflow: hidden;

	ul{
		display: flex;
		justify-content: space-between;
	}
	li {
		width: 364px;
		margin-right: 54px;

		span {
			display: block;
			overflow: hidden;

			img {
				display: block;
				width: 100%;
				transition: all 1s;
			}
		}
		h3 {
			color: #333;
			font-size: 18px;
			text-align: center;
		}
		p {
			margin: 0;
			color: #666;
			text-align: center;
		}
	}

	li:last-child {
		margin-right: 0;
	}
	 li:hover img {
		transform: scale(1.1, 1.1);
	}
	@media screen and (max-width: 1200px) {
		ul{
			flex-direction: column;
		}
		li{
			width: auto;
			margin: 0 auto;
			
			h3 {
				padding: 14px 0 8px;
			}
		}
		li:last-child{
			margin-right: auto;
		}
		li + li {
			margin-top: 20px;
		}
	}
}






</style>
